import React from 'react';
import Head from 'next/head';
import ReactDOMServer from 'react-dom/server';
import { get, filter, isEmpty, includes, defaults, split, set, has } from 'lodash';
import classnames from 'classnames';
import { Sticky, StickyContainer } from 'react-sticky';
import { Provider } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import SearchApp from '../../js/SearchApp';
import { AppState } from '../../src/models/AppState';
import { StackRankedProductsState } from '../../src/state/StackRankedProductsState';
import { ProductsPageState } from '../../src/state/ProductsPageState';
import { CacheManager, CacheKey } from '../../src/lib/CacheManager';
import { SearchkitProvider } from 'searchkit';
import { SearchBar } from '../../src/components/discovery/SearchBar';
import { Products } from '../../src/components/discovery/products/Products';
import { HowCanWeHelp } from '../../src/components/discovery/HowCanWeHelp';
import { IBMComLoading } from '../../src/components/ui/idl/IBMComLoading';
import { Config } from '../../src/core/utils/Config';
import { IBMComDotcomShell } from '../../src/components/ui/idl/IBMComDotcomShell';
import { LocaleLinks } from '../../src/components/ui/document/LocaleLinks';
import { GlobalEvents } from '../../src/components/ui/GlobalEvents';
import { IBMComSearchkitManager } from '../../src/core/IBMComSearchkitManager';
import { Utils } from '../../src/core/utils/Utils';
import { TranslationAPI } from '@carbon/ibmdotcom-services';
import { LinkWithIcon } from '@carbon/ibmdotcom-react';
import { ArrowRight } from '../../src/components/icons';
import mastheadL1Data from "./data/masthead-l1.json";

export default class CLPSearchApp extends SearchApp {
  constructor(props) {
    super(props);
    this.initializeLogging();
    this.searchkit = new IBMComSearchkitManager(Config.URL_SEARCH_BACKEND_HOST, {
      useHistory: !Config.isServer(),
      searchOnLoad: false,
      defaultSize: Config.DEFAULT_SIZE,
      pageType: 'products'
    }, {
      results: props[0].results,
      state: props[0].state,
      referrer: props[3],
      acceptLanguages: props[4]
    });

    this.searchkit.state.footerTranslations = props[2];
    this.searchkit.state.renderQueries = props[5]

    this.searchkit.translateFunction = key => {
      const cclc = `${get(props, '[0].state.cc', 'us')}-${get(props, '[0].state.lang', 'en')}`,
        translationsInCCLC = get(props, `[1].${cclc}`);

      return get(translationsInCCLC, key, key);
    };

    this.state = {
      showLoader: false,
      isSearchTipsOpen: false,
      isFiltersModalOpen: false,
      showL1Navigation: false,
      isLandingPage: ''
    };
    this.toggleSearchTips = this.toggleSearchTips.bind(this);
    this.toggleFiltersModal = this.toggleFiltersModal.bind(this);
    this.appState = AppState.initialize({
      initialState: props[6]
    });
    this.logInitialSearchOnClient();
  }

  initializeLogging () {
    // Set the APP_ID as a global
    Config.ID_APP_CURRENT = Config.ID_APPS.PRODUCTS;
  }

  logInitialSearchOnClient () {
    // Set the APP_ID as a global
    Config.clientSideLogInfoOncePerSession({ action: 'unique query was made - server side', type: 'user behavior tracking', state: this.searchkit.state, /* results */ results: [] }, 'unique');
  }

  static fixSafariScrollingIssue () {
    const isClientSideSafari = Config.isServer() ? false : Config.REGEX_IS_SAFARI.test(get(window, 'navigator.userAgent'));

    if (isClientSideSafari) {
      const input = document.getElementById('ibm-search-input');

      if (!!input && input.selectionEnd > 0) {
        input.setSelectionRange(0, 0);
      }
    }
  }

  static renderProductLinks (searchkit) {
    const lang = get(searchkit, 'state.lang', 'en'),
      cc = get(searchkit, 'state.cc', 'us'),
      isEnUs = lang === 'en' && cc === 'us';

    return isEnUs && (
      <div className="ibm--searchbar__links">
        <div className="bx--grid bx--grid--condensed">
          <div className="bx--row">
            <div className="bx--col-xlg-4 bx--col-lg-4 bx--col-md-8 bx--col-sm-4"></div>
            <div className="bx--col-xlg-12 bx--col-lg-12 bx--col-md-8 bx--col-sm-4">
              <LinkWithIcon id="product-support-link" href="https://www.ibm.com/mysupport/s/?language=en_US" iconPlacement="right">
                <span>Get product support</span>
                <ArrowRight size={20} />
              </LinkWithIcon>
              <LinkWithIcon id="product-training-link" href="https://www.ibm.com/training/" iconPlacement="right">
                <span>Get product training</span>
                <ArrowRight size={20} />
              </LinkWithIcon>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static clpSearchCode (searchkit, renderQueries, searchApp) {
    const appState = searchApp.appState,
      searchInputPlaceHolderText = searchkit.translateFunction('Search'),
      isLoading = get(searchkit, 'loading', false),
      isInitialLoading = get(searchkit, 'isInitialLoading', false),
      noHits = get(searchkit, 'results.hits.total', 0) === 0,
      selectedFilters = Config.productsSelectedFilters(renderQueries),
      isLandingPage = isEmpty(selectedFilters),
      contentClassNames = classnames({
        'ibm--content': true,
        'ibm--content--is-initial-loading': isInitialLoading,
        'ibm--content--is-loading': isLoading,
        'ibm--content--no-hits': noHits,
        'ibm--content--is-landing': isLandingPage,
      }),
      showL1Navigation = get(searchApp, 'state.showL1Navigation', false),
      navigation = showL1Navigation ? { mastheadL1Data } : { navigation: 'default' },
      pageTitle = searchkit.translateFunction('Find a product');

    searchkit.titleRef = React.createRef();
    searchkit.addSearchingStatusCallback('toggleLoader', (isSearching) => {
      if (get(searchApp, 'setState')) {
        searchApp.setState({
          showLoader: isSearching
        });
      }
      LocaleLinks.updateLinks(get(searchkit, 'state.q', ''), '', true);
    });

    if (get(searchApp, 'state.isLandingPage') !== isLandingPage) {
      if (isLandingPage && typeof window !== 'undefined') {
        window.dispatchEvent(new CustomEvent('homePageView', { detail: null }));
      }
      if (get(searchApp, 'setState')) {
        searchApp.setState({
          isLandingPage
        });
      }
    }

    return (
      <Provider appState={searchApp.appState}>
        <SearchkitProvider searchkit={searchkit}>
          <>
            <GlobalEvents/>
            <IBMComDotcomShell
              mastheadProps={{
                ...navigation
              }}
              footerProps={{
                type: 'short'
              }}
            >
              <div className="ibm--heading">
                <div className="bx--grid">
                  <div className="bx--row">
                    <div className="bx--col-xlg-4 bx--col-lg-4 bx--col-md-8 bx--col-sm-4 ibm--heading__padding">
                    </div>
                    <div className="bx--col-xlg-12 bx--col-lg-12 bx--col-md-8 bx--col-sm-4 ibm--heading__content">
                      <h1 ref={searchkit.titleRef}>
                        {pageTitle}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <StickyContainer>
                <div className="ibm--sticky-header__wrapper">
                  <Sticky topOffset={80}>
                    {
                      ({ isSticky, wasSticky, style, distanceFromTop, distanceFromBottom, calculatedHeight }) => {
                        CLPSearchApp.fixSafariScrollingIssue();

                        return (
                          <div className={`ibm--sticky-header ${isSticky ? 'is-sticky' : ''}`} style={style}>
                            <SearchBar />
                          </div>
                        );
                      }
                    }
                  </Sticky>
                  { CLPSearchApp.renderProductLinks(searchkit) }
                  <div className="ibm--searchbar__after">
                  </div>
                </div>
                <div className={contentClassNames}>
                  <IBMComLoading active={isLoading} />

                  <Products isLandingPage={isLandingPage} />

                  <HowCanWeHelp />
                </div>
              </StickyContainer>
            </IBMComDotcomShell>
          </>
        </SearchkitProvider>
      </Provider>
    );
  };

  static getMastheadHeight = () => (
    Config.isServer() ?
      SearchApp.MASTHEAD_HEIGHT_DEFAULT :
      get(document.getElementsByClassName('bx--masthead__l1'), '[0].offsetHeight', SearchApp.MASTHEAD_HEIGHT_DEFAULT)
  );

  async componentDidMount () {
    this.initializeLogging();
    window.filterBy = (key, value) => {
      if (key && value) {
        this.searchkit.viewAllProducts(true, false);
        this.searchkit.setAccessorValue(key, value);
      } else {
        this.searchkit.viewAllProducts(false);
      }
    };
    window.addEventListener('showL1Navigation', (e) => {
      this.setState({
        showL1Navigation: true
      });
    });
    window.targetRecsFeaturedProducts = (offers) => {
      window.featuredProductsRecsJson = offers;
    };

    window.targetRecsTrialProducts = (offers) => {
      window.trialProductsRecsJson = offers;
    };

    set(window, 'digitalData.page.pageInfo.interact.interactInteractionPoints', {
      Merch_Products_Spotlight_01: {
        numberOfOffers: 6,
        selectors: {
          preHeadline: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--card__eyebrow',
          headline: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--card__heading',
          description: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--card__copy>p',
          ctaURL: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/href',
          ctaLabel: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link .bx--card__cta__copy',
          'data-experimentid': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-experimentid',
          'data-dynamic-content': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-dynamic-content',
          'data-driverintent': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-driverintent',
          'data-driverformat': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-driverformat',
          'data-driversource': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-driversource',
          'data-driverlocation': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-driverlocation',
          'data-drivertype': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-drivertype'
        }
      },
      Merch_Products_Trials_01: {
        numberOfOffers: 5,
        selectors: {
          preHeadline: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--card__eyebrow',
          headline: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--card__heading',
          description: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--card__copy>p',
          ctaURL: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/href',
          ctaLabel: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link .bx--card__cta__copy',
          'data-experimentid': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-experimentid',
          'data-dynamic-content': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-dynamic-content',
          'data-driverintent': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-driverintent',
          'data-driverformat': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-driverformat',
          'data-driversource': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-driversource',
          'data-driverlocation': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-driverlocation',
          'data-drivertype': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-drivertype'
        }
      },
    });

    // window.digitalData.page.pageInfo.interact.interactInteractionPoints = {
    //   Merch_Products_Spotlight_01: {
    //     numberOfOffers: 6,
    //     selectors: {
    //       preHeadline: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--card__eyebrow',
    //       headline: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--card__heading',
    //       description: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--card__copy>p',
    //       ctaURL: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/href',
    //       ctaLabel: '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link .bx--card__cta__copy',
    //       'data-experimentid': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-experimentid',
    //       'data-dynamic-content': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-dynamic-content',
    //       'data-driverintent': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-driverintent',
    //       'data-driverformat': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-driverformat',
    //       'data-driversource': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-driversource',
    //       'data-driverlocation': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-driverlocation',
    //       'data-drivertype': '.ibm--featured-products:not(.trials) .bx--card-group__cards__col .bx--link/data-drivertype'
    //     }
    //   },
    //   Merch_Products_Trials_01: {
    //     numberOfOffers: 5,
    //     selectors: {
    //       preHeadline: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--card__eyebrow',
    //       headline: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--card__heading',
    //       description: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--card__copy>p',
    //       ctaURL: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/href',
    //       ctaLabel: '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link .bx--card__cta__copy',
    //       'data-experimentid': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-experimentid',
    //       'data-dynamic-content': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-dynamic-content',
    //       'data-driverintent': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-driverintent',
    //       'data-driverformat': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-driverformat',
    //       'data-driversource': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-driversource',
    //       'data-driverlocation': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-driverlocation',
    //       'data-drivertype': '.ibm--featured-products.trials .bx--card-group__cards__col .bx--link/data-drivertype'
    //     }
    //   },
    // };

    window.addEventListener('homePageView', () => {
      window._dl.fn.promise.subscribe('waitForAdobeTarget', window.digitalData.page.pageInfo.interact.recommend.objects.initializeTarget);
    });

    document.addEventListener('at-content-rendering-succeeded', () => {
      window.digitalData.page.pageInfo.interact.recommend.objects.initializeTarget();
    });
  }

  static async initializeAsyncData (props) {
    const cacheInitialized = CacheManager.getMemoryCache(CacheKey.cacheInitialized);

    if (Config.isServer()) {
      if (cacheInitialized !== Config.FLAG_CACHEMANAGER_IS_INITIALIZED) {
        try {
          const asynchTasks = [
              StackRankedProductsState.initialize(),
              ProductsPageState.initialize(),
            ],
            promisedTasks = await Promise.all(asynchTasks);

          CacheManager.setCache(CacheKey.cacheInitialized, Config.FLAG_CACHEMANAGER_IS_INITIALIZED);

          return promisedTasks;
        } catch (err) {
          console.error(`A failure has occurred prefetching the async data: ${err}`);
          CacheManager.setCache(CacheKey.cacheInitialized, undefined);
          Config.setDataInitializationStatus(DATA_INITIALIZATION_STATUS.failed);

          return;
        }
      }
    }
  }

  static async getInitialProps(props) {
    const { asPath, ctx, req } = props,
      referrer = get(ctx, 'req.referrer', get(ctx, 'req.referer')),
      locale = Utils.getLocaleFromPath(asPath),
      queries = defaults(Config.getQueryObjectFromURL(asPath, true), {
        cc: locale.countryCode,
        lang: locale.languageCode
      }),
      renderQueries = Config.isServer() ? queries : get(this, 'searchkit.state.renderQueries'),
      cc = get(renderQueries, 'cc'),
      lang = get(renderQueries, 'lang'),
      cclc = `${cc}-${lang}`,
      urlQueryParams = asPath.split('?')[1] || '',
      searchPath = `${Config.getQueryParamsStringFromObject(renderQueries)}`,
      searchkit = new IBMComSearchkitManager(Config.URL_SEARCH_BACKEND_HOST, {
          useHistory: true,
          searchOnLoad: true,
          location: searchPath,
          pageType: 'products'
        },
        {
          cc,
          lang
        }
      );

    await CLPSearchApp.initializeAsyncData(props);

    searchkit.emitter.clear();
    Config.clientSideLogInfo({ action: 'page initial load', type: 'user behavior tracking', referrer });
    const initialAppState = getSnapshot(AppState.initialize(props));
    const translations = await Promise.all([Utils.getTranslation(cclc), Config.isServer() ? null : TranslationAPI.getTranslation({lc: lang, cc: cc})]);

    ReactDOMServer.renderToString(CLPSearchApp.clpSearchCode(searchkit, queries, this));

    return [
      ...[ await searchkit.searchFromUrlQueryProducts(searchPath) ], // 0: searchkit results & state
      ...translations, // 1: translations for static labels & 2: Carbon for ibm.com translations
      asPath, // 3: referrer
      get(req, 'headers["accept-language"]', ''), // 4: accept-language header for SSR
      ...[ renderQueries ], // 5: query string
      ...[ initialAppState ], // 6: initial appState
    ];
  }

  render () {
    const renderQueries = this.searchkit.state.renderQueries || this.searchkit.accessors.getState(),
      asPath = get(this.props, '3');

    return (
      <>
        <Head>
          <title>{this.getTitle(asPath)}</title>
        </Head>
        { CLPSearchApp.clpSearchCode(this.searchkit, renderQueries, this) }
      </>
    );
  }
}
